<template>
  <q-page>
    <div class="bg-indigo-3">
      <div class="text-h6 q-px-xl q-pt-xl">Data Tersimpan</div>
      <q-tabs
        v-model="tab"
        dense
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab name="guru" label="Guru & Karyawan" />
        <q-tab name="siswa" label="Siswa" />
      </q-tabs>
      <q-separator></q-separator>
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="guru" class="q-pa-xl bg-indigo-2">
          <q-card class="full-width q-my-md">
            <q-card-section horizontal class="row justify-between items-end">
              <q-card-section>
                <q-btn
                  outline
                  no-caps
                  color="indigo-10"
                  label="Download Guru"
                  @click="downloadGuru"
                />
              </q-card-section>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-mt-md">
            <q-card-section class="row justify-end q-gutter-md">
              <q-select
                label="Filter by Divisi"
                filled
                dense
                map-options
                v-model="seldivGuru"
                use-input
                hide-selected
                fill-input
                @input="fetchGuru('')"
                input-debounce="0"
                :options="filtereddiv"
                style="max-width:150px"
                @filter="filterFn"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey"
                      >No results</q-item-section
                    >
                  </q-item>
                </template>
              </q-select>
              <q-input
                filled
                v-model="search"
                placeholder="Cari guru berdasarkan nama"
                dense
                class="bg-white"
                style="border-radius: 5px; width: 350px;"
                @keypress.enter="fetchGuru('nama')"
              >
                <template v-slot:append>
                  <q-btn
                    flat
                    icon="search"
                    class="text-white absolute-right"
                    @click="fetchGuru('nama')"
                    style="background-color:#03913F"
                  />
                </template>
              </q-input>
            </q-card-section>
            <q-markup-table bordered dense class="col-12">
              <thead class="text-blue-grey-14">
                <tr>
                  <th style="width:10px;" @click="fetchGuru('no_induk')">
                    No Induk
                  </th>
                  <th class="text-left" @click="fetchGuru('nama')">Nama</th>
                  <th class="text-center" @click="fetchGuru('jenis_kelamin')">
                    Gender
                  </th>
                  <th class="text-center" @click="fetchGuru('hp')">No.HP</th>
                  <th class="text-left" @click="fetchGuru('')">Divisi</th>
                  <th class="text-center" @click="fetchGuru('supervisor')">
                    Guru Kepala
                  </th>
                  <!-- <th class="text-center" @click="fetchGuru('no_kamar')">kamar</th> -->
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="listGuru.length" class>
                <tr v-for="d in listGuru" :key="d.spp_id">
                  <td class="text-left">{{ d.no_induk }}</td>
                  <td class="text-left">{{ d.nama }}</td>
                  <td class="text-center" style="width: 100px;">
                    {{ d.jenis_kelamin }}
                  </td>
                  <td class="text-center" style="width: 100px;">{{ d.hp }}</td>
                  <td>{{ d.divisi }}</td>
                  <td class="text-center" style="width: 100px;">
                    {{ d.supervisor }}
                  </td>
                  <!-- <td class="text-center" style="width: 100px;">
                  {{ d.no_kamar }}
                  </td>-->
                  <td style="width: 100px;">
                    <q-btn
                      flat
                      dense
                      class="q-mr-md"
                      @click="editData(d)"
                      icon="edit"
                    />
                    <q-btn
                      flat
                      dense
                      @click="deleteData(d)"
                      color="red"
                      icon="delete_forever"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="bg-green-1">
                <tr>
                  <td class="text-center text-grey" colspan="99">
                    tidak ada data
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
            <q-card-actions align="right">
              <q-pagination
                v-model="activePage"
                :max="Math.ceil(countGuru / 10)"
                input
                input-class="text-orange-10"
                @click="fetchGuru(orderBy)"
              />
            </q-card-actions>
          </q-card>
        </q-tab-panel>
        <q-tab-panel name="siswa" class="q-pa-xl bg-indigo-2">
          <q-card class="full-width q-my-md">
            <q-card-section horizontal class="row justify-between items-end">
              <q-card-section>
                <q-btn
                  outline
                  no-caps
                  color="indigo-10"
                  label="Download Siswa"
                  @click="downloadSiswa"
                />
              </q-card-section>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-mt-md">
            <q-card-section class="row justify-end q-gutter-md">
              <q-select
                label="Filter by Divisi"
                filled
                dense
                map-options
                v-model="seldivSiswa"
                use-input
                hide-selected
                fill-input
                @input="fetchSiswa('')"
                input-debounce="0"
                :options="filtereddiv.slice(0, 4)"
                style="max-width:150px"
                @filter="filterFn"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey"
                      >No results</q-item-section
                    >
                  </q-item>
                </template>
              </q-select>
              <q-input
                filled
                v-model="searchSiswa"
                placeholder="Cari siswa berdasarkan nama"
                dense
                class="bg-white"
                style="border-radius: 5px; width: 350px;"
                @keypress.enter="fetchSiswa('nama')"
              >
                <template v-slot:append>
                  <q-btn
                    flat
                    icon="search"
                    class="text-white absolute-right"
                    @click="fetchSiswa('nama')"
                    style="background-color:#03913F"
                  />
                </template>
              </q-input>
            </q-card-section>
            <q-markup-table bordered dense class="col-12">
              <thead class="text-blue-grey-14">
                <tr>
                  <th style="width:10px;" @click="fetchSiswa('no_induk')">
                    No Induk
                  </th>
                  <th class="text-left" @click="fetchSiswa('nama')">Nama</th>
                  <th class="text-center" @click="fetchSiswa('jenis_kelamin')">
                    Gender
                  </th>
                  <th class="text-center" @click="fetchSiswa('')">Divisi</th>
                  <th class="text-left" @click="fetchSiswa('')">Gedung</th>
                  <th class="text-center" @click="fetchSiswa('no_kamar')">
                    kamar
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="listSiswa.length" class>
                <tr v-for="d in listSiswa" :key="d.spp_id">
                  <td class="text-left">{{ d.no_induk }}</td>
                  <td class="text-left">{{ d.nama }}</td>
                  <td class="text-center" style="width: 100px;">
                    {{ d.jenis_kelamin }}
                  </td>
                  <td class="text-left">{{ d.divisi }}</td>
                  <td class="text-left">{{ d.gedung }}</td>
                  <td class="text-center" style="width: 100px;">
                    {{ d.no_kamar }}
                  </td>
                  <td style="width: 100px;">
                    <q-btn
                      flat
                      dense
                      class="q-mr-md"
                      @click="editData(d)"
                      icon="edit"
                    />
                    <q-btn
                      flat
                      dense
                      @click="deleteData(d)"
                      color="red"
                      icon="delete_forever"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="bg-green-1">
                <tr>
                  <td class="text-center text-grey" colspan="99">
                    tidak ada data
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
            <q-card-actions align="right">
              <q-pagination
                v-model="activePage2"
                :max="Math.ceil(countSiswa / 10)"
                input
                input-class="text-orange-10"
                @click="fetchSiswa(orderBy)"
              />
            </q-card-actions>
          </q-card>
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <q-dialog
      v-model="newSiswa"
      persistent
      class="row justify-center items-center"
    >
      <q-card class="input-form">
        <q-card-section class="bg-blue-3">Input Data Pendidik</q-card-section>
        <q-card-section class="row q-gutter-md">
          <q-input
            outlined
            dense
            stack-label
            v-model="databaru.nama"
            label="Nama"
            class="col-grow"
          />
          <q-input
            outlined
            dense
            stack-label
            v-model="databaru.no_induk"
            label="No Induk"
            class="col-grow"
          />
        </q-card-section>
        <q-card-section class="row q-gutter-md">
          <q-input
            outlined
            dense
            stack-label
            v-model="databaru.tempat_lahir"
            label="Tempat Lahir"
            class="col-grow"
          />
          <q-select
            outlined
            dense
            stack-label
            v-model="databaru.jenis_kelamin"
            :options="genderOption"
            map-options
            emit-value
            label="Jenis Kelamin"
            class="col-grow"
          />
        </q-card-section>
        <q-card-section class="row q-gutter-md">
          <q-input
            outlined
            dense
            stack-label
            v-model="databaru.kelas"
            label="Kelas"
            class="col-grow"
          />

          <q-input
            outlined
            dense
            stack-label
            v-model="databaru.kode_kelas"
            label="Kode Kelas"
            class="col-grow"
          />
        </q-card-section>
        <q-card-section class="row q-gutter-md">
          <q-input
            outlined
            dense
            stack-label
            v-model="databaru.no_kamar"
            label="Nomor Kamar"
            class="col-grow"
          />
        </q-card-section>
        <q-card-action>
          <q-btn class="full-width" label="Submit" @click="onSubmit" />
        </q-card-action>
      </q-card>
    </q-dialog>

    <!-- dialog delete -->
    <q-dialog v-model="confirmDelete" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>Apakah akan menghapus data ini?</q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirmDelete = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="deleteData(selectedData)"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- dialog edit  -->
    <q-dialog v-model="confirmEdit" persistent>
      <q-card>
        <q-card-section
          class="text-center text-white"
          style="background-color:#232979;"
          >Form Edit Data Pokok</q-card-section
        >
        <q-card-section style="width: 400px;">
          <label class="text-grey">No Induk</label>
          <q-input
            dense
            outlined
            v-model="selectedData.no_induk"
            class="full-width"
            :rules="[val => !!val || 'Wajib diisi']"
          />
          <label class="text-grey">Nama</label>
          <q-input
            dense
            outlined
            v-model="selectedData.nama"
            class="full-width"
            :rules="[val => !!val || 'Wajib diisi']"
          />
          <label class="text-grey">Jenis Kelamin</label>
          <q-btn-toggle
            v-model="selectedData.jenis_kelamin"
            class="q-mb-md"
            spread
            no-caps
            toggle-color="deep-orange"
            color="white"
            text-color="black"
            :options="[
              { label: 'Pria', value: 'L' },
              { label: 'Wanita', value: 'P' }
            ]"
          />
          <div v-if="selectedData.jabatan != 'SISWA'">
            <label class="text-grey">No.HP</label>
            <q-input
              dense
              outlined
              v-model="selectedData.hp"
              class="full-width"
              :rules="[val => !!val || 'Wajib diisi']"
            />
          </div>
          <label class="text-grey">Divisi</label>
          <q-select
            outlined
            dense
            map-options
            v-model="selectedData.divisi"
            use-input
            hide-selected
            fill-input
            bg-color="white"
            input-debounce="0"
            :options="filtereddiv"
            style
            @filter="filterFn"
            class="q-mb-md"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">No results</q-item-section>
              </q-item>
            </template>
          </q-select>
          <template v-if="selectedData.jabatan == 'SISWA'">
            <label class="text-grey">Gedung</label>
            <q-input
              dense
              outlined
              v-model="selectedData.gedung"
              class="full-width"
              :rules="[val => !!val || 'Wajib diisi']"
            />
            <label class="text-grey">No Kamar</label>
            <q-input
              dense
              outlined
              v-model="selectedData.no_kamar"
              class="full-width"
              :rules="[val => !!val || 'Wajib diisi']"
            />
          </template>
          <template v-else>
            <label class="text-grey">Supervisor</label>
            <q-input
              dense
              outlined
              v-model="selectedData.supervisor"
              class="full-width"
              :rules="[val => !!val || 'Wajib diisi']"
            />
          </template>
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirmEdit = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="editData(selectedData)"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<style></style>

<script>
import moment from "moment";
import Papa from "papaparse";
import XLSX from "xlsx";

export default {
  name: "Quarantine",
  data() {
    return {
      seldivSiswa: "Semua Divisi",
      seldivGuru: "Semua Divisi",
      filtereddiv: [],

      file: null,

      tab: "guru",
      search: "",
      searchSiswa: "",

      // mycsv: null,
      decodedcsv: [],
      // failed: [],

      listGuru: [],
      listGuruAll: [],
      countGuru: 0,
      listSiswa: [],
      listSiswaAll: [],
      countSiswa: 0,
      orderBy: "",
      orderType: "",
      activePage: 1,
      activePage2: 1,

      excelColumnsGuru: [
        {
          label: "No Induk",
          field: "no_induk"
        },
        {
          label: "Nama",
          field: "nama"
        },
        {
          label: "Jenis Kelamin",
          field: "jenis_kelamin"
        },
        {
          label: "Jabatan",
          field: "jabatan"
        },
        {
          label: "Divisi",
          field: "divisi"
        },
        {
          label: "Guru Kepala",
          field: "supervisor"
        }
      ],
      excelColumnsSiswa: [
        {
          label: "No Induk",
          field: "no_induk"
        },
        {
          label: "Nama",
          field: "nama"
        },
        {
          label: "Jenis Kelamin",
          field: "jenis_kelamin"
        },
        {
          label: "Divisi",
          field: "divisi"
        },
        {
          label: "Gedung",
          field: "gedung"
        },
        {
          label: "Kamar",
          field: "no_kamar"
        }
      ],

      newGuru: false,
      newSiswa: false,
      databaru: {},
      genderOption: [
        { value: "L", label: "Laki-laki" },
        { value: "P", label: "Perempuan" }
      ],
      parseCsv: null,
      // preview: false,
      confirmDelete: false,
      selectedData: {},
      confirmEdit: false
    };
  },
  mounted() {
    this.fetchGuru("no_induk");
    this.fetchSiswa("no_induk");
  },
  methods: {
    async downloadSiswa() {
      this.seldivSiswa = "Semua Divisi";
      await this.fetchSiswa();

      await this.$http.get("/list_siswa_all/", {}).then(result => {
        this.listSiswaAll = result.data;
      });

      let columns = this.excelColumnsSiswa;
      let divisi = ["SMP PA", "SMP PI", "MA"];

      let dataSiswa = this.listSiswaAll;

      let siswaALL = [];

      // get data guru per divisi
      divisi.forEach(element => {
        siswaALL.push(dataSiswa.filter(x => x.divisi == element));
      });

      let filename = "Data Pokok Siswa";

      //OBJ siswa
      let createXLSLFormatObjALL = [];

      let newXlsHeader = [];
      // let vm = this;
      if (columns.length === 0) {
        return;
      }
      if (dataSiswa.length === 0) {
        return;
      }

      columns.forEach((value, index) => {
        newXlsHeader.push(value.label);
      });

      for (let i in siswaALL) {
        let tempOBJ = [];
        siswaALL[i].forEach((value, index) => {
          let innerRowData = [];
          columns.forEach((val, index) => {
            if (val.dataFormat && typeof val.dataFormat === "function") {
              innerRowData.push(val.dataFormat(value[val.field]));
            } else {
              innerRowData.push(value[val.field]);
            }
          });
          tempOBJ.push(innerRowData);
        });
        tempOBJ.unshift(newXlsHeader);
        createXLSLFormatObjALL.push(tempOBJ);
      }

      filename = filename + ".xlsx";

      let wb = XLSX.utils.book_new(),
        wsS_SMPPA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[0]),
        wsS_SMPPI = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[1]),
        wsS_MA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[2]);
      XLSX.utils.book_append_sheet(wb, wsS_SMPPA, " siswa SMP PUTRA");
      XLSX.utils.book_append_sheet(wb, wsS_SMPPI, " siswa SMP PUTRI");
      XLSX.utils.book_append_sheet(wb, wsS_MA, " siswa MA");
      XLSX.writeFile(wb, filename);
    },
    async downloadGuru() {
      this.seldivGuru = "Semua Divisi";
      await this.fetchGuru();

      await this.$http.get("/list_guru_all", {}).then(result => {
        this.listGuruAll = result.data;
      });

      let columns = this.excelColumnsGuru;
      let divisi = this.$DIVISI;

      let dataGuru = this.listGuruAll;

      let guruALL = [];

      // get data guru per divisi
      divisi.forEach(element => {
        guruALL.push(dataGuru.filter(x => x.divisi == element));
      });

      let filename = "Data Pokok Guru";

      //OBJ siswa
      let createXLSLFormatObjALL = [];

      let newXlsHeader = [];
      // let vm = this;
      if (columns.length === 0) {
        return;
      }
      if (dataGuru.length === 0) {
        return;
      }

      columns.forEach((value, index) => {
        newXlsHeader.push(value.label);
      });

      for (let i in guruALL) {
        let tempOBJ = [];
        guruALL[i].forEach((value, index) => {
          let innerRowData = [];
          columns.forEach((val, index) => {
            if (val.dataFormat && typeof val.dataFormat === "function") {
              innerRowData.push(val.dataFormat(value[val.field]));
            } else {
              innerRowData.push(value[val.field]);
            }
          });
          tempOBJ.push(innerRowData);
        });
        tempOBJ.unshift(newXlsHeader);
        createXLSLFormatObjALL.push(tempOBJ);
      }

      filename = filename + ".xlsx";

      let wb = XLSX.utils.book_new(),
        wsG_MA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[1]),
        wsG_SMPPA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[2]),
        wsG_SMPPI = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[3]),
        wsG_STEI = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[4]),
        wsG_mahad = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[5]),
        wsG_SDQ = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[6]),
        wsG_RA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[7]),
        wsG_BPW = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[8]),
        wsG_LDS = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[9]),
        wsG_KY = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[10]),
        wsG_SY = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[11]),
        wsG_HY = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[12]),
        wsG_ADM = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[13]),
        wsG_SDM = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[14]),
        wsG_RT = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[15]),
        wsG_sarpras = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[16]),
        wsG_usaha = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[17]),
        wsG_pengasuhan_pa = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[18]),
        wsG_pengasuhan_pi = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[19]),
        wsG_perguruan = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[20]),
        wsG_pustaka = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[21]),
        wsG_laboran = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[22]),
        wsG_TPA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[23]);
      XLSX.utils.book_append_sheet(wb, wsG_SMPPA, "SMP PUTRA");
      XLSX.utils.book_append_sheet(wb, wsG_SMPPI, "SMP PUTRI");
      XLSX.utils.book_append_sheet(wb, wsG_MA, "MA");
      XLSX.utils.book_append_sheet(wb, wsG_STEI, "STEI");
      XLSX.utils.book_append_sheet(wb, wsG_mahad, "Ma'had Ally");
      XLSX.utils.book_append_sheet(wb, wsG_SDQ, "SDQ");
      XLSX.utils.book_append_sheet(wb, wsG_RA, "RA");
      XLSX.utils.book_append_sheet(wb, wsG_BPW, "BPW");
      XLSX.utils.book_append_sheet(wb, wsG_LDS, "LDS");
      XLSX.utils.book_append_sheet(wb, wsG_KY, "Keuangan Yayasan");
      XLSX.utils.book_append_sheet(wb, wsG_SY, "Sekertariat Yayasan");
      XLSX.utils.book_append_sheet(wb, wsG_HY, "HRD Yayasan");
      XLSX.utils.book_append_sheet(wb, wsG_ADM, "ADM & HUMAS");
      XLSX.utils.book_append_sheet(wb, wsG_SDM, "SDM & Penjaminan Mutu");
      XLSX.utils.book_append_sheet(wb, wsG_RT, "RT");
      XLSX.utils.book_append_sheet(wb, wsG_sarpras, "Sarpras");
      XLSX.utils.book_append_sheet(wb, wsG_usaha, "Usaha");
      XLSX.utils.book_append_sheet(wb, wsG_pengasuhan_pa, "Pengasuhan PA");
      XLSX.utils.book_append_sheet(wb, wsG_pengasuhan_pi, "Pengasuhan PI");
      XLSX.utils.book_append_sheet(wb, wsG_perguruan, "Perguruan");
      XLSX.utils.book_append_sheet(wb, wsG_pustaka, "Pustaka");
      XLSX.utils.book_append_sheet(wb, wsG_laboran, "Laboran");
      XLSX.utils.book_append_sheet(wb, wsG_TPA, "TPA");
      XLSX.writeFile(wb, filename);
    },
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.filtereddiv = this.$DIVISI.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    fetchGuru(order) {
      this.orderBy = "nama";
      this.orderType = "ASC";

      var src = "%25";
      if (this.search != "") src = this.search;

      this.$http
        .get(
          "/list_guru/" +
            src +
            "/" +
            this.orderBy +
            "/" +
            this.orderType +
            "/" +
            (this.activePage - 1) * 10 +
            "/" +
            this.seldivGuru,
          {}
        )
        .then(result => {
          this.listGuru = result.data;
        });
      this.$http
        .get("/count_guru/" + src + "/" + this.seldivGuru, {})
        .then(result => {
          this.countGuru = result.data.count;
        });
    },
    fetchSiswa(order) {
      this.orderBy = "nama";
      this.orderType = "ASC";

      var src = "%25";
      if (this.searchSiswa != "") src = this.searchSiswa;

      this.$http
        .get(
          "/list_siswa/" +
            src +
            "/" +
            this.orderBy +
            "/" +
            this.orderType +
            "/" +
            (this.activePage2 - 1) * 10 +
            "/" +
            this.seldivSiswa,
          {}
        )
        .then(result => {
          this.listSiswa = result.data;
        });

      this.$http
        .get("/count_siswa/" + src + "/" + this.seldivSiswa, {})
        .then(result => {
          this.countSiswa = result.data.count;
        });
    },
    onSubmit() {
      this.newGuru = false;
      this.databaru.jabatan = "SISWA";
      this.$http.post("/new_siswa", this.databaru, {}).then(result => {
        this.databaru = {};
      });
    },

    editData(data) {
      if (this.confirmEdit == false) {
        this.selectedData = data;
        this.confirmEdit = true;
      } else {
        //Save Data
        let temphp = data.hp.split("");
        if (temphp[0] == "0") {
          temphp[0] = "+62";
        }
        data.hp = temphp.join("");
        this.$http.put("/edit_data_pokok/" + data.id, data, {}).then(result => {
          this.fetchGuru("no_induk");
          this.fetchSiswa("no_induk");
          this.selectedData = {};
          this.confirmEdit = false;
        });
      }
    },
    deleteData(data) {
      if (this.confirmDelete == false) {
        this.selectedData = data;
        this.confirmDelete = true;
      } else {
        this.$http.delete("/data_pokok/" + data.id, {}).then(result => {
          this.fetchGuru("no_induk");
          this.fetchSiswa("no_induk");
          this.selectedData = {};
          this.confirmDelete = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.my-card {
  min-width: 250px;
  background-color: #b9f6ca;
  color: #9e9e9e;
}
.input-form {
  width: 600px;
}
</style>
