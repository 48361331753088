import { render, staticRenderFns } from "./DataPokok.vue?vue&type=template&id=62ff7a38&scoped=true&"
import script from "./DataPokok.vue?vue&type=script&lang=js&"
export * from "./DataPokok.vue?vue&type=script&lang=js&"
import style1 from "./DataPokok.vue?vue&type=style&index=1&id=62ff7a38&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62ff7a38",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QCard,QCardSection,QBtn,QSelect,QItem,QItemSection,QInput,QMarkupTable,QCardActions,QPagination,QDialog,QBtnToggle});
